@import "open-props/style";

* {
  --primary-color: white;
  --secondary-color: var(--gray-1);
  --dark-color: var(--gray-5);
  --faded-text-color: var(--gray-6);
  --blue-color: #1C6EA4;
  --blue-highlight-color: #DCEDF9;

  --calender-header-color: var(--gray-4);
  --calender-header-text-color: var(--gray-0);
  --calender-header-subtext-color: var(--blue-4);
  --calendar-even-row-color: var(--gray-1);
  --calendar-odd-row-color: var(--gray-2);
  --calendar-row-text-color: var(--blue-3);
  --calendar-row-not-in-text-color: var(--gray-5);
  --calendar-row-with-bg-text-color: var(--gray-2);


  --calendar-start-color: #5FACBF;
  --calendar-participant-reminder-color: #FD8721;
  --calendar-participant-deadline-color: #EC5B5B;
  --calendar-observer-start-color: #688D77;
  --calendar-observer-reminder-color: #995FD3;
  --calendar-observer-deadline-color: #50514F;
}

body {
  margin: 0;
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {box-sizing:border-box;}

.center {
  display: grid;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
  gap: 5px;
}

.left {
  justify-content: start;
  justify-self: start;
}

.right {
  justify-content: end;
  justify-self: end;
}

.grid-full-row {
  grid-column: 1 / -1;
}

.m1 {
  margin: 0.6rem;
}

.mr-1 {
  margin-right: 0.6rem;
}

.ml-1 {
  margin-left: 0.6rem;
}
.my-1 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.mb-2 {
  margin-bottom: 1rem;
}

.small-icon {
  font-size: 0.8rem;
}

.red-icon-row {
  background: #dc3545;
}

.text-yellow {
  color: #f59f00;
}

.text-green {
  color: #2fb344;
}

.text-small {
  font-size: .8rem;
}

.selected {
  background: var(--blue-highlight-color)!important;
}

/* .Complete {
  background: var(--green-1);
} */

.Incomplete {
  background: var(--orange-2);
}

.past-due {
  background: var(--red-3)  
}

.sub-title {
  color: var(--dark-color);
  display: inline-block;
  padding-left: 0.25rem;
  /* font-size: 1.2rem; */
}

.icon-hover:hover {
  color: var(--gray-7);
}
.icon-hover {
  color: var(--dark-color);
}

.active {
  color: var(--gray-7)!important;
}

input {
  font-size: 16px;
}