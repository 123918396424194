.participant {
  background: var(--gray-1);
}

.observer {
  background: var(--gray-3);
}

div.nextMonth {
  color: var(--calendar-row-not-in-text-color);
}
